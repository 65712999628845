import * as React from 'react';

const SvgComponent = () => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 107.3 107.3">
    <title>{'Asset 1'}</title>
    <g data-name="Layer 2">
      <g data-name="\u56FE\u5C42 1">
        <path
          d="M45.29 53.9V85a.81.81 0 0 0 .81.81h11.55a.81.81 0 0 0 .81-.81V53.4h8.37a.82.82 0 0 0 .81-.74l.8-9.53a.8.8 0 0 0-.8-.87h-9.18V35.5a2.87 2.87 0 0 1 2.87-2.87h6.45a.81.81 0 0 0 .81-.81V22.3a.81.81 0 0 0-.81-.81h-10.9a11.59 11.59 0 0 0-11.59 11.59v9.18h-5.77a.81.81 0 0 0-.81.81v9.52a.81.81 0 0 0 .81.81h5.77Z"
          style={{
            fillRule: 'evenodd',
          }}
          fill="#4189c9"
        />
        <path
          fill="#4189c9"
          d="M88.49 6.89a11.94 11.94 0 0 1 11.92 11.92v69.68a11.94 11.94 0 0 1-11.92 11.92H18.81A11.94 11.94 0 0 1 6.89 88.49V18.81A11.94 11.94 0 0 1 18.81 6.89h69.68m0-6.89H18.81A18.81 18.81 0 0 0 0 18.81v69.68a18.81 18.81 0 0 0 18.81 18.81h69.68a18.81 18.81 0 0 0 18.81-18.81V18.81A18.81 18.81 0 0 0 88.49 0Z"
        />
      </g>
    </g>
  </svg>
);

export default SvgComponent;
