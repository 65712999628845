import React from 'react';
import styled from 'styled-components';
import White from '../../Icons/White';
import { Image } from 'components';
import headerImage from 'assets/images/header.jpg';

export const HeaderIntroSection = () => {
  return (
    <IntroSection>
      <Image
        src={headerImage}
        alt="Jourve background"
        width="100%"
        height="100%"
        position="absolute"
      />
      <div className="table-cell">
        <div className="header-logo-image">
          <White />
        </div>
      </div>

      <MouseScrollDiv>
        <div className="scroll"></div>
      </MouseScrollDiv>
    </IntroSection>
  );
};

const MouseScrollDiv = styled.div`
  position: absolute;
  width: 20px;
  height: 35px;
  bottom: 100px;
  left: 50%;
  margin-left: -12px;
  border-radius: 15px;
  border: 1px solid var(--color-header);
  animation: intro 1s;

  & .scroll {
    display: block;
    width: 1px;
    height: 5px;
    margin: 6px auto;
    border-radius: 4px;
    background: var(--color-header);
    animation: finger 2s infinite;
  }

  @keyframes intro {
    0% {
      opacity: 0;
      transform: translateY(40px);
    }

    100% {
      opacity: 1;
      transform: translateY(0);
    }
  }

  @-webkit-keyframes finger {
    0% {
      opacity: 1;
    }

    100% {
      opacity: 0;
      transform: translateY(20px);
    }
  }

  @keyframes finger {
    0% {
      opacity: 1;
    }

    100% {
      opacity: 0;
      transform: translateY(20px);
    }
  }
`;

const IntroSection = styled.section`
  text-align: center;
  display: table;
  height: 100vh;
  width: 100%;
  background-attachment: fixed;
  background-position: center center;
  background-size: cover;
  position: relative;
  overflow: hidden;

  &:before {
    background: var(--color-jourve-dark);
    opacity: 0.3;
    content: '';
    left: 0;
    top: 0;
    position: absolute;
    height: 100%;
    width: 100%;
    z-index: 1;
  }

  & .table-cell {
    display: table-cell;
    vertical-align: middle;
    position: relative;
    z-index: 2;
  }

  & .header-logo-image {
    width: 100%;
    max-width: 300px;
    margin: auto;
  }
`;
