import * as React from 'react';

const SvgComponent = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    viewBox="0 0 107.3 107.3"
  >
    <defs>
      <linearGradient
        id="a"
        x1={20.63}
        y1={53.65}
        x2={87.72}
        y2={53.65}
        gradientUnits="userSpaceOnUse"
      >
        <stop offset={0} stopColor="#ffbb36" />
        <stop offset={0.35} stopColor="#ff5176" />
        <stop offset={0.52} stopColor="#f63395" />
        <stop offset={0.74} stopColor="#a436d2" />
        <stop offset={1} stopColor="#5f4eed" />
      </linearGradient>
      <linearGradient
        id="b"
        x1={0}
        y1={53.65}
        x2={107.3}
        y2={53.65}
        xlinkHref="#a"
      />
    </defs>
    <title>{'Asset 5'}</title>
    <g data-name="Layer 2">
      <g data-name="\u56FE\u5C42 1">
        <path
          d="M71.82 32.46a3.95 3.95 0 1 0 3.94 3.94 3.93 3.93 0 0 0-3.94-3.94Zm-17.53 5.06a16.57 16.57 0 1 0 16.56 16.56 16.58 16.58 0 0 0-16.56-16.56Zm0 27.18A10.62 10.62 0 1 1 64.9 54.08 10.63 10.63 0 0 1 54.29 64.7Zm33.43-24.39a20.2 20.2 0 0 0-20.21-20.2H40.84a20.2 20.2 0 0 0-20.21 20.2V67a20.2 20.2 0 0 0 20.21 20.2h26.67A20.2 20.2 0 0 0 87.72 67ZM81.39 67a13.89 13.89 0 0 1-13.88 13.87H40.84A13.88 13.88 0 0 1 27 67V40.31a13.87 13.87 0 0 1 13.84-13.87h26.67a13.88 13.88 0 0 1 13.88 13.87Z"
          style={{
            fill: 'url(#a)',
          }}
        />
        <path
          d="M88.49 6.89a11.94 11.94 0 0 1 11.92 11.92v69.68a11.94 11.94 0 0 1-11.92 11.92H18.81A11.94 11.94 0 0 1 6.89 88.49V18.81A11.94 11.94 0 0 1 18.81 6.89h69.68m0-6.89H18.81A18.81 18.81 0 0 0 0 18.81v69.68a18.81 18.81 0 0 0 18.81 18.81h69.68a18.81 18.81 0 0 0 18.81-18.81V18.81A18.81 18.81 0 0 0 88.49 0Z"
          style={{
            fill: 'url(#b)',
          }}
        />
      </g>
    </g>
  </svg>
);

export default SvgComponent;
