import React from 'react';
import styled from 'styled-components';
import FacebookIcon from '../../Icons/Facebook';
import InstagramIcon from '../../Icons/Instagram';

type NavbarToggleProps = {
  show: boolean;
  onShowToggle: () => void;
};

type Menu = {
  to: string;
  title: string;
};

const menuList: Menu[] = [
  { to: '/', title: 'Home' },
  { to: '/destinations', title: 'Our Destinations' },
  { to: '/about', title: 'Who are we?' },
];

export const HeaderNavbarToggle: React.FC<NavbarToggleProps> = ({
  show,
  onShowToggle,
}) => {
  return (
    <nav>
      <NavUl show={show}>
        <NavLi className="box-label">8Jourve</NavLi>
        {menuList.map((menu) => (
          <NavLi key={menu.to}>
            <a>
              <span onClick={onShowToggle}>{menu.title}</span>
            </a>
          </NavLi>
        ))}
        {/* <li v-for='list in lists' :key='list.to' >
                <NuxtLink :to='list.to'>
                    <span @click='$emit('hide')'>{{ list.title }}</span>
                </NuxtLink>
            </li> */}

        <NavLi className="box-label">FOLLOW US</NavLi>

        <NavLi className="box-social">
          <a
            className="facebook"
            href="https://www.facebook.com/8jourve"
            target="_blank"
            rel="noreferrer"
          >
            <FacebookIcon />
          </a>
        </NavLi>
        <NavLi className="box-social">
          <a
            className="instagram"
            href="https://www.instagram.com/8jourve"
            target="_blank"
            rel="noreferrer"
          >
            <InstagramIcon />
          </a>
        </NavLi>
      </NavUl>
    </nav>
  );
};

const NavUl = styled.ul<{ show: boolean }>`
  position: fixed;
  left: 0;
  top: 0;
  height: 100vh;
  width: 100vw;
  background: rgb(66 62 55 / 97%);
  z-index: 8;
  text-align: center;
  padding: 50px 0;
  backface-visibility: hidden;
  overflow: auto;
  transform: ${({ show }) =>
    show ? 'translateY(0)' : 'translateY(-100%)'};
  transition-property: transform;
  transition-duration: 0.4s;
  list-style: none;
  padding: 150px 0 0;

  & .box-label {
    color: var(--color-jourve);
    text-transform: uppercase;
    font-weight: 700;
    font-size: 17px;
    margin: 2.4em 0 0.8em;
  }

  & .box-social {
    display: inline-block;
    margin: 10px 10px;
  }

  & .box-social a {
    width: 30px;
    height: 30px;
    padding: 0;
    font-size: 20px;
  }
`;

const NavLi = styled.li`
  font-size: 1.375rem;
  font-weight: 300;
  margin: 0.2em 0;
  text-transform: capitalize;

  & span {
    font-size: 18px;
  }

  & a {
    display: inline-block;
    padding: 0.4em 1em;
    border-radius: 0.25em;
    transition: all 0.2s;
    color: var(--color-jourve-light);
    text-decoration: none;
    letter-spacing: 2px;
    cursor: pointer;
  }

  & a:focus {
    outline: none;
  }

  & a.active {
    font-size: 21px;
    color: var(--color-jourve-blue);
  }

  & a:hover {
    text-decoration: none;
    color: var(--color-jourve-light);
    opacity: 0.7;
  }
`;
