import React from 'react';
import styled from 'styled-components';

interface Styles {
  justifyContent?: string;
  alignItems?: string;
  margin?: string;
  padding?: string;
}

interface Props extends Styles {
  children: React.ReactNode;
}

export const Flex: React.FC<Props> = ({ children, ...styles }) => (
  <Holder {...styles}>{children}</Holder>
);

const Holder = styled.div<Styles>`
  display: flex;
  justify-content: ${({ justifyContent }) =>
    justifyContent || 'flex-start'};
  align-items: ${({ alignItems }) => alignItems || 'flex-start'};
  margin: ${({ margin }) => margin || '0'};
  padding: ${({ padding }) => padding || '0'};
`;
