import { useState } from 'react';
import { HeaderIntroSection as IntroSection } from './Header/HeaderIntroSection';
import { HeaderNavbar as Navbar } from './Header/HeaderNavbar';
import { HeaderNavbarToggle as NavbarToggle } from './Header/HeaderNavbarToggle';

const Header = () => {
  const [showNavbar, setShowNavbar] = useState(false);

  const showNavbarHandler = () => {
    setShowNavbar((preShowNavbar) => !preShowNavbar);
  };

  return (
    <header id="main-header-jourve">
      <div className="container-fluid">
        <Navbar show={showNavbar} onShowToggle={showNavbarHandler} />
        <NavbarToggle
          show={showNavbar}
          onShowToggle={showNavbarHandler}
        />

        <IntroSection />
      </div>
    </header>
  );
};

export default Header;
