import React from 'react';
import styled from 'styled-components';

interface Props {
  children: React.ReactNode;
}

export const Container: React.FC<Props> = ({ children }) => {
  return <Holder>{children}</Holder>;
};

const Holder = styled.div`
  width: 100%;
  max-width: 1280px;
  padding: 0 40px;
  margin: 0 auto;
`;
